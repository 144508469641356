
@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: 400;
    src: local('Eventsia Extra Light'), url('../webfonts/EventsiaExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: 500;
    src: local('Eventsia Light'), url('../webfonts/EventsiaLight.woff') format('woff');
}


@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: 600;
    src: local('Eventsia Medium'), url('../webfonts/EventsiaMedium.woff') format('woff');
}


@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: bold;
    src: local('Eventsia Bold'), url('../webfonts/EventsiaBold.woff') format('woff');
}


/*@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: normal;
    src: local('Eventsia Extra Bold'), url('../webfonts/EventsiaExtraBold.woff') format('woff');
}


@font-face {
    font-family: 'Eventsia';
    font-style: normal;
    font-weight: normal;
    src: local('Eventsia Black'), url('../webfonts/EventsiaBlack.woff') format('woff');
}
    */
